<template>
 <div class="newsletter">
    <div class="newsletter-form">
      <p class="error-msg" v-if="errors.status">{{ errors.message }}</p>
      <form id="sform" name="sform" method="post" v-on:submit="subsvalidate">
        <input
          type="email"
          class="form-control"
          placeholder="Enter Your Email Address..."
          name="email"
          id="email"
          v-model="subs.email_id"
          required="required"
        />
        <button
          type="button"
          name="submit1"
          id="submit1"
          v-on:click="subsvalidate()"
        >
        <i class="ti-comment-alt"></i>
        </button>
        <p class="success-msg" v-if="this.subs.dataSubs">
          Your subscription request has been sent!...
        </p>
      </form>
    </div>
  </div>
</template>
<style>

.error-msg{
    color: red;
    font-size: 18px;
    font-weight: bold;
}
.success-msg{
    color:green;
    font-size: 18px;
    font-weight: bold;
}
</style>
<script>
export default {
  name: "sform",
  data() {
    return {
      subs: {
        email_id: "",
        dataSubs: false,
      },
      errors: {
        status: false,
        message: "",
      },
    };
  },
  methods: {
    subsvalidate() {
      const emailregex = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
      if (this.subs.email_id == "") {
        this.errors.status = true;
        this.errors.message = "Please  Enter Email ID";
      } else if (!emailregex.test(this.subs.email_id)) {
        this.errors.status = true;
        this.errors.message = "Please  Enter a Valid Email ID";
      } else {
        console.warn("Your subscription request has been sent!...");
        this.errors.message = "";
        this.addnewsletter();
      }
    },

    async addnewsletter() {
      const res = await fetch(`${this.apiURL}api/mail/send`, {
        method: "POST",
        body: JSON.stringify({
          to: 'info@manipalnatural.com',
          subject: 'Newsletter Subscription For Ocuflora',
          name:'NA',
          phone:'NA',
          message: 'Newsletter Subscribed For Ocuflora...',
          email: this.subs.email_id,
          account: "manipalnatural",
        }),
        ...this.reqOptions,
      });
      this.subs.dataSubs = true;
      this.subs.dataSubs = true;
      this.subs.email_id = "";
      console.warn(res);
      if (res.status === 201) {
        this.subs.dataSubs = true;
        this.subs.email_id = "";
      }
    },
  },
};
</script>
